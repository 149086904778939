import React from 'react';
import {Box, Typography,  Grid, Container} from '@mui/material';
import mission from '../mission.jpg'
import vision from '../vision.jpg'
import './mission.css'

// import excellence from '../excellence.png'

function Mission() {
    return (
        <Box className="Section"
             sx={{
                 p: 5,

             }}>
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="card" style={{backgroundImage: `url(${mission})`}}>
                            <div className="content" style={{height:"150px"}}>
                                <Typography  sx={{fontSize:"1rem"}}>
                                    To provide companionate and quality medical services for the needy.

                                </Typography>
                                <Typography varient="h1" sx={{color:'Red',fontSize:'2rem'}}>Mission</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="card" style={{backgroundImage: `url(${vision})`}} >
                            <div className="content">
                                <Typography sx={{fontSize:"1rem"}}>
                                    Magizhon has the commitment to excellence is our motto, which greets our customers, keeping in mind their maximum benefits. Commitment towards quality health services with honest, reliable and dependable for our customers We differentiate ourselves through our unvarying focus on 3 core values – Timely, Quality, and Transparency.
                                </Typography>
                                <Typography sx={{color:'red',fontSize:'2rem'}}>Vision</Typography>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}

export default Mission;

