import React from 'react';
import JaiNavbar from './navbar';
import ColorInversionFooter from './footer'
import './contact.css'
import {Box, Link, Breadcrumbs, Typography} from '@mui/material';
import Enquiry from './enquiry';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt, faMobileAlt, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import contact from '../contact.jpg'

function Contact() {

    return (
        <>
            <JaiNavbar/>
            <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 10, marginLeft: 10}}>
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">Contact US</Typography>
            </Breadcrumbs>
            <Box className="Section contact-body">
                <div className="contact-form">
                    <div className="second-container">
                        <h2>Send Us A Message</h2>
                        <p style={{textAlign: "center"}}>Healing People through Modular Medical Equipments & Devices</p>
                        <Enquiry/>
                    </div>
                    <div className="first-container"
                         style={{background: `linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${contact}) center center/cover no-repeat`}}>
                        <div className="info-container">
                            <div>

                                <h3><FontAwesomeIcon icon={faMapMarkerAlt} style={{marginRight: 10}}/>Office Address</h3>
                                <p>No 171-4, Pondicherry-Villupuram Bypass Road, Villiayanur, Pondicherry-605110</p>
                            </div>

                            <div>

                                <h3><FontAwesomeIcon icon={faMobileAlt} style={{marginRight: 10}}/> Lets Talk</h3>
                                <p><a href="tel:+918754678999" style={{color:"#00ad5f"}}>+91 8754678999</a></p>
<p><a href="tel:+918678992555" style={{color:"#00ad5f"}}>+91 8678992555</a></p>


                            </div>
                            <div>

                                <h3><FontAwesomeIcon icon={faEnvelope} style={{marginRight: 10}}/> General Support</h3>
                                <a href="mailto:jayakumar@magizhon.com" style={{color:"#00ad5f"}}>jayakumar@magizhon.com</a>

                            </div>
                        </div>
                    </div>

                </div>

            </Box>
            <ColorInversionFooter/>
        </>
    );
}

export default Contact;