import React, { useState, useEffect } from 'react';
import CardMedia from '@mui/material/CardMedia';

const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <CardMedia
      component="img"
      height="194"
      image={images[currentImageIndex]}
      alt={`Image ${currentImageIndex + 1}`}
      style={{ width: '100%', objectFit: 'fill' }}
    />
  );
};

export default ImageSlider;
