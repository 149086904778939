import React, {useState} from 'react';
import {Card, CardMedia, CardHeader, Grid, Typography, Divider, useMediaQuery} from '@mui/material';
import ImageSlider from './imageslider'
import home from '../home.jpg'
import passdoor from '../hospital/passbox.jpg'
import otdoor from '../hospital/modular-door.jpg'
import biosafetyCabinet from '../hospital/biosafety-cabinet.jpg'
import cleenRoomGlassPanel from '../hospital/cleen-room-glass-panel.jpg'
import corridor from '../hospital/corridor.jpg'
import doubleLeafOtDoor from '../hospital/double-leaf-ot-door.jpg'
import fumehood from '../hospital/fume-hood.jpg'
import modularSsOt from '../hospital/modular-ss-ot.jpg'
import modularIcuSetup from '../hospital/modular-icu-setup.jpeg'
import ppglOt from '../hospital/ppgl-ot.jpg'
import ssOperaationTheatre from '../hospital/ss-operation-theatre.jpg'
import ssWashingSinkTable from '../hospital/ss-washing-sink-table.jpg'
import ssWashSink from '../hospital/ss-wash-sink.jpg'
import otTable from '../hospital/ot-table.jpg'
import otLight from '../hospital/ot-light.jpg'
import icucot from '../hospital/icucot.jpg'
import icumontior from '../hospital/icumontior.jpg'
import icumontior1 from '../hospital/icumontior1.jpg'
import icumontior2 from '../hospital/icumontior2.jpg'

import OxygenConcentrator from '../homecare/OxygenConcentrator.jpg'
import OxygenConcentratorOne from '../homecare/OxygenConcentrator1.jpg'
import OxygenConcentratorTwo from '../homecare/OxygenConcentrator2.jpg'
import bipap1 from '../homecare/bipap1.jpg'
import bipap2 from '../homecare/bipap2.jpg'
import bipap3 from '../homecare/bipap3.jpg'
import bipap4 from '../homecare/bipap4.jpg'
import bipap5 from '../homecare/bipap5.jpg'
import cpap1 from '../homecare/cpap1.jpg'
import cpap2 from '../homecare/cpap2.jpg'
import cpap3 from '../homecare/cpap3.jpg'
import pulse1 from '../homecare/pulse1.jpg'
import pulse2 from '../homecare/pulse2.jpg'
import suctionApparatus1 from '../homecare/suctionapparatus1.jpg';
import suctionApparatus2 from '../homecare/suctionapparatus2.jpg';
import cpapmask from '../homecare/cpapmask.jpg';
import bipapmask from '../homecare/bipapmask.jpg';
import sleepStudy from '../homecare/Sleepstudy.jpg';
import Nebulizer from '../homecare/Nebulizer.jpg';
import Nebulizer1 from '../homecare/Nebulizer1.jpg';

import stethoscope from '../clinic/tethoscope.jpg'
import stethoscope1 from '../clinic/stethoscope1.jpg'
import stethoscope2 from '../clinic/stethoscope.jpg'
import thermometer from '../clinic/thermometer.jpg'
import weightMachine from '../clinic/weightmachine.jpg'
import wheelChair from '../clinic/wheelChairs.jpg'
import wheelChair1 from '../clinic/wheelChairs1.jpg'
import fowlerCot from '../clinic/fowlerCot.jpg'
import Glucometer from '../clinic/Glucometer.jpg'
import Glucometer1 from '../clinic/Glucometer1.jpg'
import Glucometer2 from '../clinic/Glucometer2.jpg'
import firstAid from '../clinic/firstaidBox.jpg'
import firstAid1 from '../clinic/firstaidBox1.jpg'
import Otoscope from '../clinic/Otoscope.jpg'

function ProductList({handleopen, route}) {

    const productMediaQuery = useMediaQuery('(max-width:768px)');
    const [hoveredCard, setHoveredCard] = useState(null);

    const hoverImg = ["First aid Kit","Glucometer","ICU Monitor","Wheel Chairs","Stethoscope","Nebulizer","CPAP", "BIPAP", "Oxygen Concentrator","CPAP/BIPAP mask","Pulse Oximeter","Suction Apparatus"]

    const handleMouseEnter = (cardName) => {
        setHoveredCard(cardName);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const products =
        {
            "home-care": [
                {
                    "name": "Oxygen Concentrator",
                    "img": OxygenConcentratorOne,
                    "listimg": [OxygenConcentratorOne, OxygenConcentratorTwo, OxygenConcentrator]
                },
                {"name": "BIPAP", "img": bipap1, "listimg": [bipap1, bipap2, bipap3, bipap4, bipap5]},
                {"name": "CPAP", "img": cpap2, "listimg": [cpap2, cpap1, cpap3]},
                {"name": "Sleep Study", "img": sleepStudy},
                {"name": "Nebulizer", "img": Nebulizer , "listimg": [Nebulizer,Nebulizer1]},
                {"name": "Suction Apparatus", "img": suctionApparatus1,"listimg":[suctionApparatus1,suctionApparatus2]},
                {"name": "Pulse Oximeter", "img": pulse1, "listimg": [pulse1,pulse2]},
                {"name": "CPAP/BIPAP mask", "img": cpapmask, "listimg": [cpapmask,bipapmask]}
            ],
            "clinic": [
                {"name": "Hospital Fowler Cot", "img": fowlerCot},
                {"name": "Wheel Chairs", "img": wheelChair, "listimg": [wheelChair,wheelChair1]},
                {"name": "Glucometer", "img": Glucometer, "listimg": [Glucometer,Glucometer1,Glucometer2]},
                {"name": "Thermometer", "img": thermometer},
                {"name": "First aid Kit", "img": firstAid, "listimg": [firstAid,firstAid1]},
                {"name": "Weight machine", "img": weightMachine},
                {"name": "Otoscope", "img": Otoscope},
                {"name": "Stethoscope", "img": stethoscope, "listimg":[stethoscope,stethoscope1,stethoscope2]}
            ],
            "hospital": [
                {"name": "Stainless Steel Operation Theatre", "img": ssOperaationTheatre},
                {"name": "Modular Stainless Steel OT", "img": modularSsOt},
                {"name": "PPGL OT", "img": ppglOt},
                {"name": "Modular ICU Setup", "img": modularIcuSetup},
                {"name": "PassBox", "img": passdoor},
                {"name": "OT Door", "img": otdoor},
                {"name": "OT table", "img": otTable},
                {"name": "OT Light", "img": otLight},

                {"name": "Biosafety Cabinet", "img": biosafetyCabinet},
                {"name": "Clean Room Glass Panel", "img": cleenRoomGlassPanel},
                {"name": "Corridor", "img": corridor},
                {"name": "Double Leaf OT Door", "img": doubleLeafOtDoor},
                {"name": "Fume Hood", "img": fumehood},


                {"name": "Stainless Steel Washing Sink Table", "img": ssWashingSinkTable},
                {"name": "Stainless Steel Wash Sink", "img": ssWashSink},
                {"name": "ICU Cot", "img": icucot},
                {"name": "ICU Monitor", "img": icumontior,"listimg":[icumontior,icumontior1,icumontior2]},


            ]
        }


    return (
        <div>
            {Object.keys(products).map((category, index) => (
                <div key={index} style={{marginTop: 10}}>
                    {(route.route === category) ?
                        (<>                     <Divider/>
                            <Typography sx={{m: 2, color: "Green"}}
                                        variant="h4">{route.route === 'home-care' ? 'Home Care' : route.route === 'clinic' ? 'Clinic' : route.route === 'hospital' ? 'Hospital' : 'Services'} Services
                            </Typography>
                            <Grid container spacing={2} justifyContent={productMediaQuery ? 'center' : ''}>
                                {products[category].map((card, idx) => (

                                    <Grid item key={idx}>
                                        <Card sx={{maxWidth: 250}} className='product-card'
                                              onMouseEnter={() => handleMouseEnter(card.name)}
                                              onMouseLeave={handleMouseLeave}>
                                            <CardHeader
                                                sx={{fontSize: 10, backgroundColor: "#00000029", height: 60}}
                                                title={
                                                    <Typography variant="h6" style={{fontSize: '17px'}}>
                                                        {card.name}
                                                    </Typography>

                                                }
                                            />
                                            {(hoveredCard === card.name && hoverImg.includes(card.name) ?
                                                <ImageSlider images={card.listimg}/> :
                                                <CardMedia
                                                    component="img"
                                                    height="194"
                                                    image={card.img ? card.img : home}
                                                    alt={card.name}
                                                    style={{width: '100%', objectFit: 'fill'}}
                                                />)}
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid> </>) : ''}

                </div>
            ))}


        </div>
    )
        ;
}

export default ProductList;