import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faShop} from '@fortawesome/free-solid-svg-icons';
import './footer.css'
import CopyrightIcon from '@mui/icons-material/Copyright';
// import trade from '../trade.png'
import {Box, Grid} from '@mui/material';
import logo from "../navbar.png";

const ColorInversionFooter = () => {
    const footer = {
        general: [{name: 'About Us', href: '/about'}, {
            name: 'Privacy Policy',
            href: '/'
        }, {name: 'Terms & Conditions', href: '/'}, {name: 'Return & Refund Policy', href: '/'}],
        solution: [{name: 'Home Care', href: '/home-care'}, {
            name: 'Doctors Clinic',
            href: '/clinic'
        }, {name: 'OT & ICU Setups', href: '/hospital'},]
    };


    return (
        <Box className="Section">
            <footer>
                <div>
                <span className="logo" style={{fontSize: 30, color: "green"}}>
                    <img src={logo} className="mx-1" alt="Magizhon" style={{'height':50
                    }}/>
                </span>
                </div>
                <Grid container justifyContent="space-evenly"  direction="row"
                      alignItems="center" sx={{marginBottom: 5}}>

                    <Grid item sm={6} md={3} xs={12} p={1} >
                        <span className="footer-cat">Medical Devices</span>
                        <ul className='footer-cat-links active'>
                            {footer.solution.map((site) => (<li><a href={site.href}><span>{site.name}</span></a></li>))}

                        </ul>
                    </Grid>
                    <Grid item sm={6} md={3} xs={12} p={1} >
                        <span className="footer-cat">Quick Links</span>
                        <ul className='footer-cat-links active'>
                            {footer.general.map((site) => (<li><a href={site.href}><span>{site.name}</span></a></li>))}

                        </ul>
                    </Grid>
                    <Grid item sm={6} md={3} xs={12} p={1} >
                        <span className="footer-cat">Rental Services</span>
<ul className="footer-cat-links active">
                            <li>Pondicherry</li>
                             <li>Cuddalore</li>
                            <li>Karikal</li>

                            <li>Chidambaram</li>
                            <li>Tindivanam</li>

                        </ul>                    </Grid>
                    <Grid item sm={6} md={3} xs={12} p={1} >
                        <span className="footer-cat"></span>
                        <ul className="footer-cat-links active">
                            <li>Villupuram</li>
                            <li>Chennai</li>
                            <li>Tiruvannamalai</li>
                            <li>chengalpattu</li>

                        </ul>

                    </Grid>
                </Grid>
                <Grid container justifyContent="center"
                      alignItems="center" sx={{marginBottom: 5}} spacing={5} >

                    <Grid item sm={6} md={12} xs={12}>
                        <div id="address">
                            <center>
                                <span className="footer-cat">Showroom Location</span>
                                <ul>
                                    <li>
                                        <FontAwesomeIcon icon={faShop} style={{height: 40, marginTop: 5}}/>
                                        <div>MAGIZHON<br/>
                                            No 171-4, Pondicherry-Villupuram Bypass Road, Villiayanur, Pondicherry-605110
                                        </div>

                                    </li>
                                </ul>
                            </center>
                        </div>


                    </Grid>
                </Grid>

                <div id="copyright">
                    <span >

                    Designed by <a style={{color:"green"}} href="/">Magizhon </a>
                </span>
                    <CopyrightIcon style={{marginBottom: -6}}/> <span
                    style={{marginTop: 20}}>All Rights Reserved 2024</span>
                </div>

            </footer>

        </Box>
    );
}

export default ColorInversionFooter;