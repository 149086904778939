import React from 'react';
import Navbar from './navbar';
import {Typography, Grid, Breadcrumbs, Link} from '@mui/material';
import ColorInversionFooter from './footer'
import Mission from './mission';
import about from '../about1.jpg'
import './about.css'
import DoneIcon from '@mui/icons-material/Done';
function About() {

    const AboutStyle = {m: 2, fontFamily: 'monospace', fontSize: "1.2rem"}

    return (
        <>
            <Navbar/>
            <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 10, mx: 3}}>
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">About US</Typography>
            </Breadcrumbs>
            <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={12} md={5} lg={5} sx={{m: 2}}>
                    <img src={about} alt="Lobby" style={{width: '100%', height: '100%'}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{color: 'black'}}>
                    <Typography variant="h2" sx={{m: 2, fontFamily: 'cursive'}}
                                className="font-bold text-3xl my-4 sm:text-4xl">
                        About <span className="text-indigo-600 font-bold">Our Company</span></Typography>
                    <Typography sx={{mx: 4, fontFamily: 'monospace', fontSize: "1.2rem"}}>
Magizhon, founded in 2024 and based in Puducherry, collaborates with a prominent manufacturer, Mars Sterile Equipments, specializing in Modular Operation Theater (MOT). </Typography>
                    <Typography sx={AboutStyle}>
                        Our company is uniquely crafted to cater to the diverse medical device requirements of different customer segments, including
                    </Typography>
                    <ul>
                        <li>
                            <Typography sx={AboutStyle}>
                                <DoneIcon  /> 	Home Care Medical Devices and Equipment
                            </Typography>
                        </li>

                        <li>
                            <Typography sx={AboutStyle}>
                                <DoneIcon  />  Doctor`s Clinic
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={AboutStyle}>
                               <DoneIcon  />   Modular OT & ICU setup
                            </Typography>
                        </li>
                    </ul>

                </Grid>
            </Grid>
            <Mission></Mission>
            <ColorInversionFooter/>
        </>
    );
}

export default About;