import React, {useState, useEffect} from 'react';
import NavBar from './navbar';
import {
    Box
} from '@mui/material';
import './home.css'
import about from '../aboutus.jpg'
import home from '../home2.jpg'
import ColorInversionFooter from './footer'
import Enquiry from './enquiry';
import homeCare from '../homecare1.jpg';
import doctorClinic from '../doctorclinic.png';
import icu from '../icu1.jpg';
import animation_logo from '../animation_logo.gif'


function Home() {
    const [showGif, setShowGif] = useState(true);
    useEffect(() => {
        const gifTimeout = setTimeout(() => {
            setShowGif(false);
        }, 5000);

        return () => {
            clearTimeout(gifTimeout);
        };
    }, []);

    function Info({imgSrc, title, description, link}) {
        return (
            <div className="info">
      <span>
        <img src={imgSrc} alt="" style={{height: "72%"}}/>
      </span>
                <div className="info__text">
                    <h1>{title}</h1>
                    <p>{description}</p>
                    <h5 className=""><a style={{color: "blue"}} href={link}>READ MORE</a></h5>
                </div>
            </div>
        );
    }

    const [popupActive, setPopupActive] = useState(false);

    const togglePopup = () => {
        setPopupActive(!popupActive);
    };

    const closePopup = () => {
        const popupBlock = document.getElementById('popup-reg');
        popupBlock.style.opacity = '0';
        popupBlock.querySelector('.popup-content').style.marginTop = '350px';

        setTimeout(() => {
            setPopupActive(false);
            popupBlock.style.opacity = '';
            popupBlock.querySelector('.popup-content').style.marginTop = '';
        }, 600);
    };


    return (
        <>
            {showGif ? (
                    <div className="app-container">
                        <img className="centered-gif" src={animation_logo} alt="Loading..."/>
                    </div>
                ) :
                <>
                    <NavBar/>
                    <Box className="Section" id="one"
                         style={{backgroundImage: `linear-gradient(75deg, rgb(6 6 6 / 64%) 8%, transparent), url(${home})`}}>

                        <div className="home-content">
                            <div className="text-content">


                            </div>

                        </div>

                    </Box>
                    <Box className="Section" sx={{backgroundColor: 'lightgreen', padding: 5, marginBottom: 10}}>
                        <div>
                            <h1 >Modular Medical Equipment and Medical Devices
                                Showroom</h1>
                        </div>
                    </Box>

                    <Box className="Section goblack" id="one-half">
                <span className="about-img"><img
                    src={about}
                    alt=""/></span>
                        <div className="half-content">
                            <div className="half__text">
                                <h1>About Us</h1>
                                <p><strong>Magizhon </strong> is dedicated to bringing <strong>smiles </strong>through innovative medical equipment and devices. Our commitment to quality and cutting-edge technology aims to enhance lives and make a positive impact on healthcare. With dedicated services, we ensure smiles and well-being for those we serve.</p>
                            </div>

                        </div>
                    </Box>
                    <Box className="Section" sx={{backgroundColor: 'lightgreen', padding: 5, marginBottom: 10}}>
                        <div>
                            <h1 className="title">For more details</h1>

                            <button type="button" className="main-btn-rect popup-btn " onClick={togglePopup}>
                                Click me
                            </button>
                            {popupActive && (
                                <div id="popup-reg" className="popup active">
                                    <div className="popup-content">
                                        <div className="event-header">
                                            <Enquiry/>
                                        </div>
                                        <span className="fade-out main-btn-circle" onClick={closePopup}>
                                  ╳
                                </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                    <Box className="Section" id="two">
                        <div className="heading">
                            <h1>What We Do</h1>
                            <p className="lightblack">
                                Modular medical equipment and devices are readily accessible throughout India.
                            </p>
                        </div>

                        <div className="container">
                            <Info
                                imgSrc={homeCare}
                                title="Home Care"
                                description="Basic home care to advance mini ICU setup at home."
                                link="home-care"
                            />

                            <Info
                                imgSrc={doctorClinic}
                                title="Doctors Clinic"
                                description="Available Basic, Classic and Premium medical devices for the customer need."
                                link="clinic"
                            />

                            <Info
                                imgSrc={icu}
                                title="OT & ICU"
                                description="Basic to NABH Standard hospital fabrication."
                                link="hospital"
                            />
                        </div>
                    </Box>


                    <ColorInversionFooter/>
                </>

            }

        </>
    )

}

export default Home;