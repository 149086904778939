import './App.css';
import {Routes,Route} from "react-router-dom";
import Home from './components/home';
import About from './components/about';
import Contact from './components/contact';
import JaiwinPrivacy from './components/privacy';
import JaiwinTerm from './components/term';
import JaiwinProduct from './components/products';
import JaiwinReturnPrivacy from './components/returnprivacy';

function App() {
  return (
    <>
    <div className="App">
        
    </div>
    <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/hospital' element={<JaiwinProduct route="hospital" />} />
        <Route path='/home-care' element={<JaiwinProduct route="home-care" />} />
        <Route path='/clinic' element={<JaiwinProduct route="clinic" />  } />

        <Route path='/terms' element={<JaiwinTerm />} />
        <Route path='/privacy' element={<JaiwinPrivacy />} />
        <Route path='/refund-policy' element={<JaiwinReturnPrivacy />} />
      </Routes></>
    
  );
}

export default App;
